import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from 'src/app/dictionary/dictionary.model';
import axios, { AxiosResponse } from 'axios';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  private url = `${environment?.rest?.strapiUrl}/api/diccionarios`;

  getDictionaryWords(term: string, type: string): Observable<ApiResponse> {
    const url =
      type === 'firstLetter'
        ? `${this.url}?sort[0]=Concepto:asc&filters[Concepto][$startsWith]=${term}&pagination[limit]=100`
        : `${this.url}?&filters[Concepto][$containsi]=${term}&pagination[limit]=100`;

    return from(this.fetchData(url)).pipe(
      catchError((error) => {
        console.error('Error fetching dictionary words:', error);
        return of({ data: [], error: 'Error fetching data' } as ApiResponse);
      })
    );
  }

  getAllWords(): Observable<ApiResponse> {
    const url = `${this.url}?sort[0]=Concepto:asc`;
    return from(this.fetchData(url)).pipe(
      catchError((error) => {
        console.error('Error fetching all words:', error);
        return of({ data: [], error: 'Error fetching data' } as ApiResponse);
      })
    );
  }

  private async fetchData(url: string): Promise<ApiResponse> {
    try {
      const response: AxiosResponse<ApiResponse> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error in fetchData:', error);
      throw error;
    }
  }
}
