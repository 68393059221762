import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DictionaryService } from '../core/services/dictionary.service';
import { Terms } from './dictionary.model';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ds-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss'],
})
export class DictionaryComponent implements OnInit {
  loading = true;
  search = '';
  selectedLetter = '';
  allTerms: Terms[] = [];
  term: Terms[] = [];
  paginatedTerms: Terms[] = [];
  message = `No hay resultados`;
  previousLetter = '';
  placeholder = `Ingrese su búsqueda`;
  regex;
  currentPage = 1;
  itemsPerPage = 5;
  totalPages = 0;
  totalItems = 0;
  letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dictionyService: DictionaryService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.getAllWords();

    this.route.queryParams.subscribe((params) => {
      const search = params.search;
      if (search) {
        this.search = search;
        this.SearchDictionaryByParam(search);
      }
    });
  }

  normalizeString(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  searchDictionary(): void {
    this.term = this.allTerms;
    this.message = `No hay resultados`;

    if (!this.search) {
      this.router.navigate(['/dictionary']);
    }

    if (this.selectedLetter) {
      this.term = this.term.filter(
        (item) =>
          this.normalizeString(item.concepto)[0] ===
          this.normalizeString(this.selectedLetter)
      );
      this.message = `No hay coincidencias con la letra ${this.selectedLetter.toUpperCase()}.`;
    }

    if (this.search) {
      this.term = this.term.filter((item) =>
        this.normalizeString(item.concepto).includes(
          this.normalizeString(this.search)
        )
      );
      this.message = `No hay coincidencias con "${this.search}". Prueba otro término.`;
    }

    this.paginateTerms();
    this.cdr.detectChanges();
  }

  SearchDictionaryByParam(term: string) {
    term = term.toLowerCase();
    this.term = this.allTerms.filter(
      (el) => el.concepto.toLowerCase() === term
    );

    this.paginateTerms();
    this.cdr.detectChanges();
  }

  setLetter(letter): void {
    if (letter !== '' && this.currentPage !== 1) {
      this.currentPage = 1;
      this.itemsPerPage = 100;
      this.cdr.detectChanges();
    }
    this.selectedLetter = letter;
    this.searchDictionary();
  }

  handleAll() {
    this.itemsPerPage = 5;
    this.searchDictionary();
  }

  getAllWords(): void {
    this.loading = true;
    this.cdr.detectChanges();
    this.dictionyService.getAllWords().subscribe(
      (data) => {
        this.loading = false;
        this.cdr.detectChanges();
        this.allTerms = data?.data?.map((item) => ({
          id: item?.id,
          concepto: item?.attributes?.Concepto,
          descripcion: item?.attributes?.Definicion?.map((def) =>
            def?.children?.map((child) => child?.text).join('')
          ).join(''),
        }));

        this.term = this.allTerms;
        this.totalItems = this.term.length;
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.changePage(1);
        this.cdr.detectChanges();

        this.regex = new RegExp(
          `\\b(${this.allTerms.map((el) => el.concepto).join('|')})\\b`,
          'gi'
        );

        const params = this.route.snapshot.queryParams;
        const search = params.search;
        if (search) {
          this.search = search;
          this.searchDictionary();
        }

        this.cdr.detectChanges();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onClickButton(): void {
    if (this.search.trim().length < 1) {
      this.notificationService.error(
        'Por favor, ingresa un término de búsqueda.'
      );
    } else {
      this.currentPage = 1;
      this.itemsPerPage = 100;
      this.cdr.detectChanges();
      this.searchDictionary();
    }
  }

  onCLickTerm(term: string): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/dictionary'], { queryParams: { search: term } });
  }

  searchTerm(term: string): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/search'], { queryParams: { query: term } });
  }

  paginateTerms(): void {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedTerms = this.term.slice(start, end);
    this.totalPages = Math.ceil(this.term.length / this.itemsPerPage);
  }

  setItemsPerPage(count: number): void {
    this.itemsPerPage = count;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.changePage(1);
  }

  changePage(page: number): void {
    this.currentPage = page;
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedTerms = this.term.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.cdr.detectChanges();
  }
}
