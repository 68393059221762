<div class="header-list-communities">
  <div class="top-list-cards">
    <h2 class="title-communities">{{data?.title}}</h2>
  </div>
  <ds-themed-loading [showMessage]="false" *ngIf="dataSource?.loading$ | async"></ds-themed-loading>
  <div *ngIf="!(dataSource?.loading$ | async) && displayedCommunities?.length > 0 else renderNotData"
    class="contend-card">
    <div *ngFor="let communitie of displayedCommunities" class="sub-contend-card" [@fadeInOut]>
      <a [routerLink]="communitie.route" class="card">
        <div *ngIf="communitie?.logo?.src !== undefined" class="icon-circle">
          <img *ngIf="communitie?.logo?.src !== undefined" [ngClass]="{'d-none': imagesLoading[communitie.id]}"
            [src]="communitie?.logo?.src" (error)="errorHandler(communitie.id)"
            (load)="successHandler(communitie.id)" />
          <ds-small-loader *ngIf="imagesLoading[communitie.id]"
            [isLoading]="imagesLoading[communitie.id]"></ds-small-loader>
        </div>
        <div class="dis">
          <div class="title">
            {{ dsoNameService.getName(communitie.payload) ? dsoNameService.getName(communitie.payload): ''}}
          </div>
          <span class="subtitle">
            {{ communitie?.collectionCount === 1 
              ? ('1 ' + ( 'text.communitie.collection.single' | translate)) 
              : (communitie?.collectionCount + ' ' + ( 'text.communitie.collection.plural' | translate)) 
          }}
          </span>
        </div>
      </a>
    </div>
  </div>
  <ng-template #renderNotData>
    <h3 class="no-data">
      Actualmente, no hay anaqueles disponibles para mostrar.
    </h3>
  </ng-template>
  <button *ngIf="!loading && dataCommunities.length > communitiesPerPage" (click)="toggleCommunities()"
    class="btn btn-primary show-more">
    {{ getToggleButtonText() }}
  </button>
</div>