import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DictionaryComponent } from './dictionary.component';
import { DictionaryRoutingModule } from './dictionary-routing.module';
import { DictionaryDescriptionComponent } from './dictionary-description/dictionary-description.component';

const DECLARATIONS = [DictionaryComponent, DictionaryDescriptionComponent];

const IMPORTS = [CommonModule, FormsModule, DictionaryRoutingModule];

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
  imports: [...IMPORTS],
})
export class DictionaryModule {}
