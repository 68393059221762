<section id="dictionary">
  <div>
    <div class="container-dictionary overflow-hidden rounded-1">
      <div class="py-7 position-relative">
        <h1 class="title-center">Diccionario Jurídico</h1>
      </div>
    </div>
    <div class="general-sub-container">
      <div class="layout-center">
        <form class="termcat-dl-search dictionaries dictionaries__form-wrapper">
          <div class="content">
            <div class="search-container">
              <div class="container-action">
                <input class="input" type="search" placeholder="{{ placeholder }}" name="inputSearch"
                  [(ngModel)]="search" (input)="searchDictionary()" />
              </div>
              <button class="btn-send" (click)="onClickButton()">Buscar</button>
            </div>
            <div class="alphabet-container">
              <div class="alphabet-container-sub">
                <input id="all" type="radio" name="alphabet" value="" (change)="setLetter('')" checked />
                <label for="all" (change)="setLetter('')" (click)="handleAll()">Todo</label>
              </div>
              <div class="items" *ngFor="let letter of letters">
                <input [id]="letter" type="radio" name="alphabet" [value]="letter" (change)="setLetter(letter)" />
                <label class="tabs" [for]="letter">
                  {{ letter.toUpperCase() }}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="second-layout-center">
        <div class="layout-center-content">
          <div class="items-per-page">
            <label for="itemsPerPage">Elementos por página:</label>
            <select [disabled]="paginatedTerms.length <= 0" id="itemsPerPage" [(ngModel)]="itemsPerPage" (change)="setItemsPerPage(itemsPerPage)">
              <option [value]="5">5</option>
              <option [value]="10" selected>10</option>
              <option [value]="20">20</option>
              <option [value]="50">50</option>
              <option [value]="50">100</option>
            </select>
          </div>
          <section *ngIf="paginatedTerms.length > 0; else notData" class="terms-container">
            <article class="items-terms" *ngFor="let term of paginatedTerms">
              <header class="clickable-heading-container">
                <a (click)="onCLickTerm(term.concepto)" class="clickable-heading">
                  {{ term.concepto }}
                </a>
                <a (click)="searchTerm(term.concepto)" class="search-term">
                  <button>
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <span>Explorar en archivos</span>
                  </button>
                </a>
              </header>
              <ds-dictionary-description [regex]="regex" [term]="term"></ds-dictionary-description>
            </article>
          </section>
        </div>
        <div *ngIf="paginatedTerms?.length > 0" class="pagination-controls">
          <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">
            <img src="../../assets/images/arrow-left.svg" />
            Anterior
          </button>
          <span>{{ currentPage }} de {{totalPages}}</span>
          <button (click)="changePage(currentPage + 1)" [disabled]="currentPage * itemsPerPage >= totalItems">
            Siguiente
            <img src="../../assets/images/arrow-right (1).svg" />
          </button>
        </div>
      </div>
      <ng-template #notData>
        <div class="alert alert-info w-100">
          {{ message }}
        </div>
      </ng-template>
    </div>
  </div>
</section>