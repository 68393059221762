/* eslint-disable curly */
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'ds-dictionary-description',
  templateUrl: './dictionary-description.component.html',
  styleUrls: ['./dictionary-description.component.scss'],
})
export class DictionaryDescriptionComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer, private router: Router) {}
  description: any;
  @Input() regex: any;
  @Input() term: any;

  ngOnInit() {
    this.getDescription();
  }

  getDescription() {
    const htmlString = this.term.descripcion.replace(this.regex, (match) => {
      if (match.toLowerCase() !== this.term.concepto.toLowerCase())
        return `<span class="clickable-term">${match}</span>`;
      return match;
    });
    this.description = this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  termClicked(term: string) {
    term = term.toLowerCase();
    this.router.navigate(['/dictionary'], { queryParams: { search: term } });
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('clickable-term')) {
      this.termClicked(target.textContent);
    }
  }
}
