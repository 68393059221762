<header>
  <div class="container-header">
    <div class="sub-container-header-nav">
      <section class="section-elements">
        <div class="elements-container">
          <div class="container-items">
            <div class="container-items-image">
              <div class="container-items-image-position">
                <a routerLink="/home" class="link-image">
                  <img loading="lazy" fetchpriority="high" fetchpriority="high" alt="Logo Poder Judicial"
                    class="logo-poder-judicial"
                    [src]="dataHeader?.righImg ? dataHeader?.righImg: '../../../assets/images/logo-poder-judicial.svg'" />
                </a>
              </div>
            </div>
          </div>
          <div class="socials-container" [ngClass]="{'open-socials': (isXsOrSm$ | async)}">
            <div class="socials-sub-container">
              <div class="socials-sub-container-logo">
                <img alt="Marcas país" class="attachment-full"
                  [src]="dataHeader?.leftImg ? dataHeader?.leftImg: '../../../assets/images/logo-RD.svg'">
              </div>
              <ds-themed-search-navbar [placeholderText]="dataHeader?.placeholder"></ds-themed-search-navbar>
              <div class="social-medias">
                <div class="container-items-social-media">
                  <div class="container-items-social-media-title">
                    <div class="elementor-widget">
                      <h2 class="elementor-heading-title">
                        <a class="link" routerLink={{dataHeader?.href}}>{{dataHeader?.contactText}}</a>
                      </h2>
                    </div>
                  </div>
                  <div class="items-container">
                    <div *ngIf="dataHeader?.icons" class="items">
                      <div class="elementor-widget-social-icons">
                        <div class="elementor-widget-container">
                          <div class="elementor-social-icons-wrapper">
                            <span *ngFor="let icon of dataHeader?.icons" class="elementor-grid-item">
                              <a class="elementor-icon elementor-social-icon" href={{icon?.href}} target="_blank">
                                <img style="width: 20px; height: 20px; object-fit: cover; scroll-snap-align: center;"
                                  [src]="icon?.image" />
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate"
      class="navbar navbar-light navbar-expand-xl flex-shrink-0 px-0">
      <div class="pl-2 custom-container">
        <button class="navbar-toggler px-0" type="button" (click)="toggleNavbar()" aria-controls="collapsingNav"
          aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
          <span [ngClass]="!getStateNavbar()  ? 'fa-times': 'fa-bars'" class="navbar-toggler-icon fas fa-fw"
            aria-hidden="true"></span>
        </button>
        <div class="login-icon-container">
          <a  *ngIf="!(isAuthenticated$ | async)" routerLink="/login" class="Btn">
            <div class="sign">
              <svg viewBox="0 0 512 512">
                <path
                  d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z">
                </path>
              </svg>
            </div>
          </a>
          <ds-avatar [user]="infoUser" [showName]="false" *ngIf="(isAuthenticated$ | async)"></ds-avatar>
        </div>
      </div>
    </nav>
  </div>
  <div [ngClass]="{'open': !(isXsOrSm$ | async)}" class="section-mobile">
    <div class="container-logo">
      <img loading="lazy" class="logo-escudo-RD" src="assets/images/Escudo-RD.svg" />
    </div>
    <div class="container-search">
      <ds-themed-search-navbar [placeholderText]="dataHeader?.placeholder"></ds-themed-search-navbar>
    </div>
  </div>
</header>