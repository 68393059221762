<nav class="navbar-ava">
  <div class="profile-section">
    <button class="profile-button" (click)="toggleDropdown()">
      <div class="user-icon" *ngIf="dataUserAction?.userName">
        <span>{{getInitials(dataUserAction?.userName)}}</span>
      </div>
      <div *ngIf="showName && dataUserAction?.userName " class="user-info">
        <h5 class="user-name">{{ dataUserAction?.userName }}</h5>
      </div>
    </button>
    <div class="profile-dropdown" *ngIf="dropdownOpen">
      <div class="content">
        <div class="info">
          <span *ngIf="dataUserAction?.userName" class="full-name">
            {{ dataUserAction.userName}}</span>
          <div *ngIf="dataUserAction?.email">
            <img class="email" src="../../../assets/images/email.svg" />
            <span>
              {{dataUserAction?.email}}
            </span>
          </div>
          <div *ngIf="dataUserAction?.phone">
            <img src="../../../assets/images/phone.svg" />
            <span>
              {{dataUserAction?.phone}}
            </span>
          </div>
          <a routerLink="/profile" (click)="toggleDropdown()">
            <img c src="../../../assets/images/profile.svg">
            <span>Perfil</span>
          </a>
        </div>
      </div>
      <div class="container-btn">
        <button (click)="logOut()">Cerrar sesión</button>
      </div>
    </div>
  </div>
</nav>